.dashboard-padding-top {
  padding-top: 10em;
}

.chart-center {
  padding-left: 25%;
}

@media all and (max-width: 699px) and (min-width: 520px), (min-width: 1151px) {
  #chart {
    width: 50%;
  }
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  #chart {
    width: 50%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  #chart {
    width: 50%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  #chart {
    width: 50%;
  }
}
