.button-padding {
  padding-top: 10em;
}
.bottom-padding {
  padding-bottom: 7em;
}
.button-groups {
  margin-right: 1em;
}
.button-font-size {
  font-size: 2vh;
}
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  position: fixed;
}
.get-started {
  font-size: 2vh;
}

.home-header {
  font-size: 5vh;
}
.container {
  max-width: 100% !important;
}

.media-buttons {
  margin-right: 8em !important;
}
