.add-prod-form {
  padding-top: 3em;
}

.add-prod-btn-row {
  margin-left: 3px;
}

.add-prod-btn-separator {
  margin-right: 10px;
}

.edit-padding-top {
  padding-top: 10em;
}
.inline-display {
  display: flex;
}

.searchForm {
  padding-top: 1em;
  width: 100%;
}

.input-width {
  width: auto;
}

.prod-margin {
  margin: 1em;
}

.prod-edit-container {
  padding-top: 12em;
}

.products-add-form-padding {
  padding-right: 5%;
  padding-left: 5%;
}

.products-create-button {
  padding-top: 4em;
}

.products-cancel-button {
  padding-top: 4em;
}

.products-padding-top {
  padding-top: 9em;
}

.search-auto-width {
  width: auto !important;
}

#table-padding {
  padding-top: 5em;
}
